import React from "react";

import {
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  styled,
} from "@mui/material";

export const PCTable: React.FC = ({ children, ...props }) => {
  return (
    <TableContainer component={Paper}>
      <Table size="small" {...props}>
        {children}
      </Table>
    </TableContainer>
  );
};

export const PCTableHead: React.FC = ({ children, ...props }) => (
  <TableHead {...props}>{children}</TableHead>
);

export const PCTableBody: React.FC = ({ children, ...props }) => (
  <TableBody {...props}>{children}</TableBody>
);

export const PCTableRow: React.FC = ({ children, ...props }) => (
  <TableRow {...props}>{children}</TableRow>
);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
}));

export const PCTableCell: React.FC = ({ children, ...props }) => (
  <StyledTableCell {...props}>{children}</StyledTableCell>
);
