import React from "react";

import { MDXProvider } from "@mdx-js/react";
import { Typography, Chip, styled } from "@mui/material";
import { Alert, AlertTitle } from "@mui/material";

import { orange } from "@mui/material/colors";
import {
  PCTable,
  PCTableHead,
  PCTableBody,
  PCTableRow,
  PCTableCell,
} from "./markdown/pc-table";

const MyH1: React.FC = ({ children }) => {
  return (
    <Typography variant="h4" component="h1" sx={{ marginBottom: 1 }}>
      {children}
    </Typography>
  );
};

const MyH2: React.FC = (props) => {
  return (
    <Typography
      variant="h5"
      component="h2"
      sx={{ marginBottom: 1, marginTop: 2 }}
      {...props}
    />
  );
};

const MyH3: React.FC = (props) => {
  return (
    <Typography
      variant="h6"
      component="h3"
      sx={{ marginBottom: 1, marginTop: 2 }}
      {...props}
    />
  );
};

const MyP: React.FC = ({ children }) => (
  <Typography component="p">{children}</Typography>
);

const InlineCodeRoot = styled(`span`)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(0, 0.5),
  "& code": {
    color: orange[900],
    fontFamily: "monospace",
  },
}));

export const InlineCode: React.FC = ({ children }) => {
  return (
    <InlineCodeRoot>
      <Typography component="code">{children}</Typography>
    </InlineCodeRoot>
  );
};

const MyLink: React.FC = ({ children, ...props }) => (
  <a target="_blank" rel="noopener noreferrer" {...props}>
    {children}
  </a>
);

const components = {
  h1: MyH1,
  h2: MyH2,
  h3: MyH3,
  p: MyP,
  inlineCode: InlineCode,
  a: MyLink,
  table: PCTable,
  thead: PCTableHead,
  tbody: PCTableBody,
  tr: PCTableRow,
  td: PCTableCell,
  th: PCTableCell,
  Chip,
  Typography,
  Alert,
  AlertTitle,
};

const MdxLayout: React.FC = ({ children }) => {
  return <MDXProvider components={components}>{children}</MDXProvider>;
};

export default MdxLayout;
